
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import VueTypedJs from "src/views/ai/components/vueTypedJs"
import { Fun } from "src/views/ai/utils/common"

@Component({
    components:
    {
        VueTypedJs
    }
})
export default class UserList extends Vue
{
    /**
     * 选中数据
     * @private
     * @returns number
     */
     private activeIndex: any = null;

    /**
     * 数据源
     * @private
     * @returns boolean
     */
     private dataList: Array<any> = [];

     /**
     * 数据
     * @private
     * @returns string
     */
     @Prop({type: Object, default: {title: "", data: []}})
    private dataSource: any;

    
    /**
     * 文字输入完成
     * @private
     */
    private onCompleteTxt()
    {
        // 滚动到最底部
        this.$emit('scollAnimation');
        Fun.addTimeData(this.dataList, this.dataSource.data, this.onKeepTyping)

    }

    // 文字持续输入
    private onKeepTyping()
    {
        // 滚动到最底部
        this.$emit('scollAnimation');
    }
    
    /**
     * 输入框移入事件
     * @private
     * @returns void
     */
    private onMessageInput()
    {
        window.scrollTo(0, document.body.scrollHeight);
    }

    /**
     * 执行方法
     * @private
     */
    private onToDo({callFunc, callFuncData}, index)
    {
        this.activeIndex = index;
        this.$emit("toDo", {callFunc, callFuncData});
    }
    
}
