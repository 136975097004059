
import { Component, Vue } from "vue-property-decorator";
import pageBack from "src/components/page-back";
import globalConfig from "src/config/index";

@Component({
    components:{
        pageBack
    }
})
export default class Login extends Vue
{
    /**
     * 返回
     */
    private customFn()
    {
        this.$emit("close-zfagreement");
    }

    /**
     * 获取当前登入企业相关配置
     * @private
     * @returns void
     */
    protected get employerConfig(): any
    {
        let appid = localStorage.getItem("appid");

        let employerConfig = globalConfig.employerConfig[appid];

        return employerConfig;
    }
}

  
