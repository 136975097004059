
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Toast, Dialog } from "vant";
import { UserService } from "src/services";
import { ILogin } from "src/model";
import { DesSecretUtils } from "src/utils";
import { tools } from "src/utils";
import privacy from "src/views/privacy.vue";
import agreement from "src/views/agreement.vue";
import zfagreement from "src/views/zf-agreement.vue"
import zfprivacy from "src/views/zf-privacy.vue"
import ermInfo from "src/views/ermInfo.vue";
import rightsAgreement from "src/views/rights-agreement.vue";
import rightsPrivacy from "src/views/rights-privacy.vue";
 import Register from "src/views/register.vue";
import globalConfig from "src/config/index";
const showRightsCode = globalConfig.showRightsCode;
// import sensorsUtils from "src/utils/sensorsUtils"
import eventBus from 'src/views/ai/utils/bus';

@Component({
     components:
     {
         agreement,
         privacy,
         rightsAgreement,
         rightsPrivacy,
         ermInfo,
         Register,
         zfagreement,
         zfprivacy
     }
 })
export default class LoginPop extends Vue
{

    /**
     * 是否显示
     * @private
     * @returns string
     */
    private showLoginPopup: boolean = true;
    
    /**
     * 数据
     * @private
     * @returns string
     */
     @Prop({type: Object, default: {triggerMethod: "", triggerData: null}})
    private dataSource: any;

    /**
     * 输入框移入事件
     * @private
     * @returns void
     */
    private onMessageInput()
    {
        window.scrollTo(0, document.body.scrollHeight);
    }
 
    /**
     * 显示协议弹框
     * @private
     * @returns boolean
     */
    private showOverlay: boolean = false;
 
    /**
     * 服务隐私协议id
     * @private
     * @returns number
     */
    private ermInfoId: any = null;

    /**
     * 是否显示服务隐私协议
     * @private
     * @returns number
     */
    private showErmInfoModal: boolean = false;

    /**
     * 是否显示服务协议
     * @private
     * @returns boolean
     */
    private showAgreement: boolean = false;

    /**
     * 是否显示隐私协议
     * @private
     * @returns boolean
     */
    private showPrivacy: boolean = false;

    /**
     * 是否显示中福服务协议
     * @private
     * @returns boolean
     */
    private showZfAgreement: boolean = false;

    /**
     * 是否显示中福隐私协议
     * @private
     * @returns boolean
     */
    private showZfPrivacy: boolean = false;

    /**
     * 是否显示权益隐私协议
     * @private
     * @returns boolean
     */
    private showRightsAgreement: boolean = false;

    /**
     * 是否显示权益隐私协议
     * @private
     * @returns boolean
     */
    private showRightsPrivacy: boolean = false;

    /**
     * 是否默认记录密码
     * @private
     * @returns boolean
     */
    private isRememberPassword: boolean = false;

    /**
     * 是否同意协议
     * @private
     * @returns boolean
     */
    private checkedAgreement: boolean = false;

    /**
     * 登录数据模型
     * @private
     * @returns ILogin
     */
    private modelData: ILogin =
    {
        code: "", // 企业编号
        account: "", // 登入账号
        password: "", // 登入密码
        loginType: null // 登录类型
    };

    /**
     * 是否显示密码
     * @private
     * @returns string
     */
    private showPwd: boolean = false;

    /**
     * 获取当前登入机构信息
     * @private
     * @returns void
     */
    private get tip(): any
    {
        let appid = localStorage.getItem("appid");
        let employerConfig = globalConfig.employerConfig[appid];
        let tempData = this.loginTypes.filter(res => res.loginType == this.modelData.loginType);
        return {
            accountTip: employerConfig && employerConfig.accountTip || "请输入" + ((tempData.length > 0 && tempData[0].loginTypeStr) || "手机号"),
            pwdTip: employerConfig && employerConfig.pwdTip || "请输入密码"
        };
    }

    /**
     * 是否显示注册
     * @private
     * @returns void
     */
    private get showRegister(): any
    {
        return this.orgInfo.isOpenRegister || false;
    }

    /**
     * 获取当前登入机构信息
     * @private
     * @returns void
     */
    private get orgInfo(): any
    {
        return this.$store.getters.orgInfo || {};
    }

    /**
     * 获取当前登入信息
     * @private
     * @returns void
     */
    private get showRights(): any
    {
        return showRightsCode.includes(localStorage.getItem("appid"));
    }

    /**
     * 获取routeName
     * @private
     * @returns void
     */
    private get routeName(): any
    {
        return this.$route.query.routeName || "";
    }

    /**
     * 组件创建钩子
     * @private
     * @returns void
     */
    private created(): void
    {
        eventBus.$emit("changeShowFooter", false);
        localStorage.removeItem("openid")
        this.modelData.code = localStorage.getItem("appid");
        // 获取机构信息
        this.getOrgInfo(this.modelData.code);
    }

    /**
     * 关闭
     * @private
     * @returns void
     */
    private onClose(): void
    {
        eventBus.$emit("changeShowFooter", true);
    }

    /**
     * 记住密码重新赋值
     * @private
     * @returns void
     */
    private onSetModelData(): void
    {
        let loginDataStr= localStorage.getItem("loginData");
        if(loginDataStr)
        {
            if (this.orgInfo.isUnited || this.loginTypes.some(res => res.loginType == JSON.parse(DesSecretUtils.encrypt(loginDataStr)).loginType))
            {
                this.isRememberPassword = true;
                this.modelData = JSON.parse(DesSecretUtils.encrypt(loginDataStr));
            }
        }
    }

    /**
     * 登录类型
     * @private
     * @returns any
     */
    private loginTypes: Array<any> = [];

    /**
     * 获取机构详情
     * @private
     * @returns void
     */
    private async getOrgInfo(code: string): Promise<void>
    {
        try
        {
            let {content: result} =  await UserService.instance.getOrgInfo(code);
            if(result.data)
            {
                if (!result.data.isUnited && result.data.loginTypes)
                {
                    this.loginTypes = result.data.loginTypes;
                    if (this.loginTypes.length > 0)
                    {
                        this.modelData.loginType = this.loginTypes[0].loginType;
                    }
                }
                this.$store.dispatch("setOrgInfo", result.data);
                if (result.data.isSelf) // 个人
                {
                    this.$router.replace({name: "register"});
                }
            }
            this.onSetModelData();
        }
        catch(err)
        {
            this.onSetModelData();
            Toast(err);
        }
    }

    /* 业务员隐私协议显示
    * @private
    * @returns void
    */
    private async onTermInfos(id): Promise<void>
    {
        this.ermInfoId = id;
        this.showErmInfoModal = true;
    }

    /* 业务员隐私协议关闭
    * @private
    * @returns void
    */
    private async onCloseErmInfoModal(): Promise<void>
    {
        this.showErmInfoModal = false;
    }

    /**
     * 登录
     * @private
     * @returns void
     */
    private async onLogin(): Promise<void>
    {
        if(this.orgInfo.isUnited)
        {
            this.uniteLogin();
        }
        else
        {
            this.onEmployerLogin();
        }
    }

    /**
     * 登录
     * @private
     * @returns void
     */
    private async onEmployerLogin(): Promise<void>
    {
        try
        {
            if(!this.checkedAgreement)
            {
                this.showOverlay = true;
                return;
            }
            let {content: result} =  await UserService.instance.login(this.modelData);
            // 绑定神策用户数据
            // sensorsUtils.bind("identity_Id",result.data.userId +"");
        
            if(this.isRememberPassword)
            {
                let loginData = DesSecretUtils.decrypt(JSON.stringify(this.modelData));
                localStorage.setItem("loginData", loginData)
            }
            else
            {
                localStorage.removeItem("loginData")
            }
            
            if(result.data)
            {
                this.$store.dispatch("setUserInfo", result.data);
                this.$nextTick(()=>
                {
                    this.$emit('toDo', {callFunc: "AfterLoginHandle", callFuncData: this.dataSource});
                    this.showLoginPopup = false;
                })
                
            }
        }
        catch(err)
        {
            Toast(err);
        }
    }

    /**
     * 业务员登录
     * @private
     * @returns void
     */
    private async uniteLogin(): Promise<void>
    {
        try
        {
            if(!this.checkedAgreement)
            {
                this.showOverlay = true;
                return;
            }
            let {content: result} =  await UserService.instance.uniteLogin(this.modelData);
            // 绑定神策用户数据
            // sensorsUtils.bind("identity_Id",result.data.userId +"");
            
            if(this.isRememberPassword)
            {
                let loginData = DesSecretUtils.decrypt(JSON.stringify(this.modelData));
                localStorage.setItem("loginData", loginData)
            }
            else
            {
                localStorage.removeItem("loginData")
            }
            

            if(result.data)
            {
                // 只有一个企业
                if(result.data.isOnlyOne)
                {
                    this.$store.dispatch("setOrgInfo", result.data.orgInfo);
                    this.$store.dispatch("setUserInfo", result.data);
                    this.$nextTick(()=>
                    {
                        if(this.routeName == 'report')
                        {
                            this.$router.replace({name: "reportAppointmentList"});
                        }
                        else
                        {
                            this.$router.replace({name: "home"});
                        }
                        
                    })
                }
                else
                {
                    this.$store.dispatch("setUserInfo", result.data);
                    this.$nextTick(()=>
                    {
                    
                        this.$router.replace({name: "employerList", query:{routeName:this.routeName}});
                    })
                }
                
                
            }
        }
        catch(err)
        {
            Toast(err);
        }
    }

    /* 同意协议
    * @private
    * @returns void
    */
    private async onAgree ()
    {
        this.checkedAgreement = true;
        this.showOverlay = false;
        // 点击同意之后直接登录
        try
        {
            this.onLogin();
        }
        catch(err)
        {
            Toast(err);
        }
    }

    /* 忘记密码密码
    * @private
    * @returns void
    */
    private onRetrievePwd(): void
    {
        this.$router.push({name: "retrievePwd"});
    }

    /* 跳转隐私协议
    * @private
    * @returns void
    */
    private onPrivacy(): void
    {
        this.showPrivacy = true;
    }

    /* 关闭隐私协议
    * @private
    * @returns void
    */
    private closePrivacy(): void
    {
        this.showPrivacy = false;
    }

    /* 跳转用户协议
    * @private
    * @returns void
    */
    private onAgreement(): void
    {
        this.showAgreement = true;
    }

    /* 关闭用户协议
    * @private
    * @returns void
    */
    private closeAgreement(): void
    {
        this.showAgreement = false;
    }

    /* 关闭中福服务协议
    * @private
    * @returns void
    */
    private closeZfAgreement(): void
    {
        this.showZfAgreement = false;
    }

    /* 关闭中福隐私政策
    * @private
    * @returns void
    */
    private closeZfpPrivacy(): void
    {
        this.showZfPrivacy = false;
    }



    /* 跳转中福服务协议
        * @private
        * @returns void
        */
    private onzfAgreement(): void
    {
        this.showZfAgreement = true;
    }

    /* 跳转中福隐私协议
        * @private
        * @returns void
        */
    private onzfPrivacy(): void
    {
        this.showZfPrivacy = true;
    }

    /* 跳转携保权益协议
    * @private
    * @returns void
    */
    private onRightsAgreement(): void
    {
        this.showRightsAgreement = true;
    }

    /* 关闭携保权益协议
    * @private
    * @returns void
    */
    private closeRightsAgreement(): void
    {
        this.showRightsAgreement = false;
    }

    /* 跳转携保隐私
    * @private
    * @returns void
    */
    private onRightsPrivacy(): void
    {
        this.showRightsPrivacy = true;
    }

    /* 跳转携保隐私
    * @private
    * @returns void
    */
    private closeRightsPrivacy(): void
    {
        this.showRightsPrivacy = false;
    }

    /* 注册
    * @private
    * @returns void
    */
    private onToRegister(): void
    {
        this.$router.push({name: "registerMain"});
    }

    /* 改变登录方式
    * @private
    * @returns void
    */
    private onChangeTab(type): void
    {
        this.modelData.loginType = type;

    }
}
