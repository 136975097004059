var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"contacts"},[_c('div',{staticClass:"logo-img"},[(_vm.orgInfo && _vm.orgInfo.logo)?_c('img',{staticClass:"logo-img-warp",attrs:{"src":_vm.orgInfo && _vm.orgInfo.logo}}):_vm._e()]),_c('van-form',{staticClass:"formN",on:{"submit":_vm.onSubmit}},[_c('van-field',{staticClass:"login-form-item",attrs:{"label":"","rules":[
              {required: true, message: '请输入真实姓名',trigger: 'onBlur' },
          ],"error-message-align":"right","placeholder":"请输入真实姓名"},model:{value:(_vm.modelData.name),callback:function ($$v) {_vm.$set(_vm.modelData, "name", $$v)},expression:"modelData.name"}}),_c('van-field',{staticClass:"login-form-item",attrs:{"error-message-align":"right","maxlength":"11","label":"","rules":[
              {required: true, message: '请输入手机号',trigger: 'onBlur' },
              { validator:_vm.validatorPhone, message: '请输入正确手机号',trigger: 'onBlur' },   
          ],"placeholder":"请填写正确的手机号以保证预约顺畅"},scopedSlots:_vm._u([{key:"button",fn:function(){return [(_vm.timerNum == 60)?_c('div',{staticStyle:{"color":"#00D5C1"},on:{"click":_vm.onGetCode}},[_vm._v("发送验证码")]):_c('div',[_vm._v(_vm._s(_vm.timerNum + '秒'))])]},proxy:true}]),model:{value:(_vm.modelData.phone),callback:function ($$v) {_vm.$set(_vm.modelData, "phone", $$v)},expression:"modelData.phone"}}),_c('van-field',{staticClass:"login-form-item",attrs:{"maxlength":"6","label":"","rules":[
              {required: true, message: '请输入验证码',trigger: 'onBlur' },
          ],"error-message-align":"right","placeholder":"请输入验证码"},model:{value:(_vm.modelData.smsCode),callback:function ($$v) {_vm.$set(_vm.modelData, "smsCode", $$v)},expression:"modelData.smsCode"}}),_c('van-field',{staticClass:"login-form-item",attrs:{"label":"","readonly":true,"value":_vm.modelData.idTypeName,"placeholder":"请选择证件类型","error-message-align":"right","is-link":"","rules":[
              {required: true, message: '请选择证件类型',trigger: 'onChange' },
          ]},on:{"click":function($event){_vm.showIdtypePicker = true}}}),_c('van-popup',{attrs:{"position":"bottom"},model:{value:(_vm.showIdtypePicker),callback:function ($$v) {_vm.showIdtypePicker=$$v},expression:"showIdtypePicker"}},[_c('van-picker',{attrs:{"value-key":"description","show-toolbar":"","columns":_vm.idTypeList},on:{"confirm":_vm.onIdTypeConfirm,"cancel":function($event){_vm.showIdtypePicker = false}}})],1),_c('van-field',{staticClass:"login-form-item",attrs:{"error-message-align":"right","rules":[
              {required: true, message: '请输入证件号码',trigger: 'onBlur' },
          ],"label":"","placeholder":"请填写正确的证件号码以保证预约顺畅"},model:{value:(_vm.modelData.cardNo),callback:function ($$v) {_vm.$set(_vm.modelData, "cardNo", $$v)},expression:"modelData.cardNo"}}),(_vm.modelData.cardType!=1)?_c('van-field',{staticClass:"login-form-item",attrs:{"label":"","input-align":"","value":_vm.modelData.birthDay,"readonly":true,"is-link":"","error-message-align":"right","placeholder":"请选择出生日期","rules":[
              {required: true, message: '请选择出生日期',trigger: 'onChange' },
          ]},on:{"click":function($event){_vm.showDate = true}}}):_vm._e(),_c('van-popup',{attrs:{"position":"bottom"},model:{value:(_vm.showDate),callback:function ($$v) {_vm.showDate=$$v},expression:"showDate"}},[_c('van-datetime-picker',{attrs:{"type":"date","title":"选择年月日","minDate":_vm.minDate,"maxDate":_vm.maxDate},on:{"confirm":_vm.onDateConfirm,"cancel":_vm.onDateCancel},model:{value:(_vm.birthDayDate),callback:function ($$v) {_vm.birthDayDate=$$v},expression:"birthDayDate"}})],1),(_vm.modelData.cardType!==1)?_c('van-field',{staticClass:"login-form-item",attrs:{"label":"","readonly":true,"value":_vm.modelData.sexName,"placeholder":"请选择性别","error-message-align":"right","is-link":"","rules":[
              {required: true, message: '请选择性别',trigger: 'onChange' },
          ]},on:{"click":function($event){_vm.showSexPicker = true}}}):_vm._e(),_c('van-popup',{attrs:{"position":"bottom"},model:{value:(_vm.showSexPicker),callback:function ($$v) {_vm.showSexPicker=$$v},expression:"showSexPicker"}},[_c('van-picker',{attrs:{"value-key":"text","show-toolbar":"","columns":_vm.sexList},on:{"confirm":_vm.onSexPickerConfirm,"cancel":function($event){_vm.showSexPicker = false}}})],1),_c('van-field',{staticClass:"login-form-item",attrs:{"label":"","readonly":true,"value":_vm.modelData.marriageName,"placeholder":"请选择婚姻状态","error-message-align":"right","is-link":"","rules":[
              {required: true, message: '请选择婚姻状态',trigger: 'onChange' },
          ]},on:{"click":function($event){_vm.showMarriagePicker = true}}}),_c('van-popup',{attrs:{"position":"bottom"},model:{value:(_vm.showMarriagePicker),callback:function ($$v) {_vm.showMarriagePicker=$$v},expression:"showMarriagePicker"}},[_c('van-picker',{attrs:{"value-key":"description","show-toolbar":"","columns":_vm.marriageStatusList},on:{"confirm":_vm.onMarriagePickerConfirm,"cancel":function($event){_vm.showMarriagePicker = false}}})],1),_c('van-field',{staticClass:"login-form-item",attrs:{"error-message-align":"right","rules":[
                {required: true, message: '请输入密码',trigger: 'onBlur' },
            ],"label":"","type":_vm.showPwd ? 'text' : 'password',"placeholder":"请输入密码"},model:{value:(_vm.modelData.password),callback:function ($$v) {_vm.$set(_vm.modelData, "password", _vm._n($$v))},expression:"modelData.password"}},[_c('template',{slot:"right-icon"},[(_vm.showPwd)?_c('van-icon',{attrs:{"name":"eye-o"},on:{"click":function($event){_vm.showPwd = !_vm.showPwd}}}):_vm._e(),(!_vm.showPwd)?_c('van-icon',{attrs:{"name":"closed-eye"},on:{"click":function($event){_vm.showPwd = !_vm.showPwd}}}):_vm._e()],1)],2),_c('div',{staticClass:"contacts-submit-btn"},[_c('button',{staticClass:"btn btn-block"},[_vm._v("注册")])])],1),_c('p',{staticClass:"account-p"},[_vm._v("正式员工请直接"),_c('i',{staticClass:"account",on:{"click":_vm.onLogin}},[_vm._v("登录")]),_vm._v("，如无法登录，可联系HR导入信息")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }