
import { Component, Vue, Watch } from "vue-property-decorator";
import { UserService, WechatService, GaodeService } from "src/services";
 import { tools } from "src/utils";
import VueTypedJs from "src/views/ai/components/vueTypedJs";
import eventBus from 'src/views/ai/utils/bus';
import * as SignalR from '@microsoft/signalr';
import globalConfig from "../../../../config";

// 组件 begin
import { OptionalAddition, SelectPackage, TitleForm, SelectHospital, WxMiniProgram, TitleButton, UserList, OrderList, NotifyMessage, AnswerQuestion, ReportList } from "src/views/ai/components/dialogue";
import Login from "src/views/ai/components/popup/loginPop";
import { CompletingInfo, AddFamily, FamilyManage } from "src/views/ai/components/popup/family";
import { PackageComparingResult, PackageComparing } from "src/views/ai/components/popup/packageComparing";
import { ToPay } from "src/views/ai/components/popup/pay";
import { AuthAgreement } from "src/views/ai/components/popup/authAgreement";

import { MenuList } from "src/views/ai/components/common";
import { promises } from "dns";
// 组件 end

Component.registerHooks([
    "beforeRouteEnter",
    "beforeRouteLeave",
    "beforeRouteUpdate" // for vue-router 2.2+
]);

/**
 * VueTypedJs https://www.npmjs.com/package/vue-typed-js
 */
@Component({
    components:
    {
        MenuList,
        VueTypedJs,
        OptionalAddition, 
        SelectPackage, 
        TitleForm, 
        SelectHospital,
        WxMiniProgram,
        TitleButton,
        UserList,
        CompletingInfo,
        Login,
        OrderList,
        NotifyMessage,
        AddFamily,
        FamilyManage,
        ToPay,
        AnswerQuestion,
        ReportList,
        AuthAgreement,
        PackageComparingResult,
        PackageComparing
    }
})
export default class Home extends Vue
{
    /**
     *  初始化第一屏内容
     * @private
     */
    private showInitPage: boolean = false;

    /**
     * 是否加载中
     * @private
     */
    private isloading: boolean = false;
    
    /**
     * 是否在演示中
     * @private
     */
    private isDemonstrate: boolean = false;

    /**
     * 是否显示消息列表
     * @private
     */
    private showMessage: boolean = false;

    /**
     * 消息
     * @private
     */
    private message: string = "";

    
    /**
     * 消息列表
     * @private
     */
    private messageList: Array<any> =[];

    /**
     * 开始演示
     * @private
     */
    private onDemonstrate()
    {
        this.isDemonstrate = true;
        setTimeout(() => {
            this.showMessage = true;
            this.showInitPage = true;
        }, 1000);
    }

    /**
     * 滚动最底部
     * @private
     */
    private scollAnimation()
    {
        window.requestAnimationFrame(()=>
        {
            setTimeout(() => {
                let data = (this.$refs.messageContent as any).childNodes;
                (this.$refs.home as any).scrollTop = data[data.length - 2].offsetTop + 100;
            }, 100);
        })
    }

    // 文字持续输入
    private onKeepTyping()
    {
        // 滚动到最底部
        this.scollAnimation();
    }

    /**
     * 打印完成后执行方法
     * @private
     */
    private onCompleteTxtFunData: any = null;

    /**
     * 文字输入完成
     * @private
     */
    private onCompleteTxt(index)
    {
        // 滚动到最底部
        this.scollAnimation();
        if (this.onCompleteTxtFunData)
        {
            this.onCompleteTxtFunData();
            this.onCompleteTxtFunData = null;
        }
    }

    // 记录已经咨询过的问题，如果已经问过了则不再显示
    private historyQuestionData = [];

    // 提示词执行方法
    private onIssueListF(data: any){
       this.onDo(data);
    };

    /**
     * 是否隐藏底部栏-用于兼容ios
     * @private
     * @returns void
     */
    private showFooter: boolean = true;
    
    /**
     * 组件进来之前
     * @private
     * @returns void
     */
    private activated(): void
    {
        localStorage.setItem("isFormAi", "true");
        // let tempData = localStorage.getItem('messageList') && JSON.parse(localStorage.getItem('messageList')) || [];
        // if (tempData.length)
        // {
        //     this.isDemonstrate = true;
        //     this.showMessage = true;
        //     this.showInitPage = false;
        //     this.messageList = tempData;
        // }
        this.initSocket();
        if (this.leaveScrollTop)
        {
            (this.$refs.home as any).scrollTop = this.leaveScrollTop + 40;
            this.leaveScrollTop = null;
        }
        eventBus.$off('changeShowFooter');
        eventBus.$on('changeShowFooter', function(blo: boolean = true){
            this.showFooter = blo;
        }.bind(this));

        
        eventBus.$off('onDo');
        eventBus.$on('onDo', function(data: any){
            this.onDo(data);
        }.bind(this));

        
        eventBus.$off('judgeData');
        eventBus.$on('judgeData', function(data: any){
            this.messageList[data.index].judgeData = data.data;
        }.bind(this));
        // window.onbeforeunload = () =>{
        //     if (this.messageList.length > 0)
        //     {
        //         let arrComp = ['Login', 'CompletingInfo', 'AddFamily', 'FamilyManage', 'PackageComparingResult', 'PackageComparing', 'ToPay', 'AuthAgreement'];
        //         let msgList = this.messageList.filter(res => !arrComp.includes(res.component));
        //         localStorage.setItem('messageList', JSON.stringify(msgList));
        //     }
        // }

        this.socketState.socketTimer = setInterval(()=>
        {
            if (!this.socketState.socket || this.socketState.socket.state == "Disconnected")
            {
                this.initSocket();
            }
        }, 1000 * 20);
    }

    private leaveScrollTop = null;

    /**
     * 页面离开前
     * @private
     * @returns void
     */
    private beforeRouteLeave(to, from, next): void
    {
        this.leaveScrollTop = (this.$refs.home as any).scrollTop;
        if (this.socketState.socketTimer) {
            clearInterval(this.socketState.socketTimer);
        }
        next();
    }

    /**
     * 输入框移入事件
     * @private
     * @returns void
     */
    private onMessageInput()
    {
        window.scrollTo(0, document.body.scrollHeight);
    }


    private socketState: any = {
        socket: null,
        roomId: null,
        socketTimer: null
    }
    
    /**
     * 底部快捷指令栏
     * @private
     * @returns void
     */
    private issueList: Array<any> = [];
    
    /**
     * 获取当前登入信息
     * @private
     * @returns void
     */
     private get userInfo(): any {
        let userInfoStorge = localStorage.getItem("userInfo");

        if (userInfoStorge) {
        return JSON.parse(userInfoStorge);
        }

        return {};
    }

    // 初始化Socket
    private initSocket = async()=>
    {
        let token = this.userInfo.accessToken;
        this.socketState.socket = new SignalR.HubConnectionBuilder()
        .configureLogging(SignalR.LogLevel.Information)
        .withAutomaticReconnect()
        .withUrl(globalConfig.signalrUrl, {
            accessTokenFactory: () => token || "",
            skipNegotiation: false,
            transport: SignalR.HttpTransportType.WebSockets
        }).build();
        
        this.socketState.socket.serverTimeoutInMilliseconds = 60 * 1000;
        await this.socketState.socket.start();
        this.initSocketListener();
    }

    // 初始化监听事件
    private initSocketListener() {
        // 接收服务端消息
        this.socketState.socket.on("Receive", this.Receive);
        // 触发服务端方法
        this.socketState.socket.on("DoSend", this.DoSend);
        // 触发user聊天
        this.socketState.socket.on("SendMessage", this.SendMessage);
    }

    // 移除监听事件
    private onOffSocketListener() {
        // 加入房间
        this.socketState.socket.off("Receive", this.Receive);
        // 触发服务端方法
        this.socketState.socket.off("DoSend", this.DoSend);
        // 触发user聊天
        this.socketState.socket.off("SendMessage", this.SendMessage);
    }

    // 接收服务端消息
    private async Receive({message, component, data})
    {
        // 重置发送消息
        this.message = "";
        // 如果是组件
        this.isloading = false;
        if (component == 'Text')
        {
            this.messageList.push(Object.assign({},{type: "textType", message: data.text}))
        }
        else if (component == 'BottomButton')    // 底部快捷指令
        {
            if(data.isAdding)
            {
                this.issueList = [...this.issueList, ...data.buttons];
            }
            else
            {
                this.issueList = [...data.buttons];
            }
        }
        else if (component == 'Router')
        {
            this.$router.push({name: data.name, query: data.data});
        }
        else
        {
            this.messageList.push(Object.assign({},{component, data}));
        }
        setTimeout(() => {
            this.scollAnimation();
        }, 100);
    }

    // 触发服务端方法
    private DoSend({triggerMethod, triggerData})
    {
        if (triggerMethod && this.socketState.socket)
        {
            if (triggerData)
            {
                this.socketState.socket.send(triggerMethod, triggerData);
            }
            else
            {
                this.socketState.socket.send(triggerMethod);
            }
        }
    }

    // 触发user聊天
    private SendMessage(message: string) {
        if(!message) return;
        // 产生自己的对话内容
        this.messageList.push(Object.assign({},{type: "user",message, noTyped: true}));
        this.scollAnimation();
    }

    // 输入文本
    private onSend(message: string) {
        if(!message || this.isloading) return;
        this.isloading = true;
        this.showInitPage = false;
        // 产生自己的对话内容
        this.messageList.push(Object.assign({},{type: "user",message}));
        setTimeout(() => {
            this.socketState.socket.send("Send",message, {});
        }, 200);
    }

    // 输入文本
    private async onDo({callFunc, callFuncData}) {
        if(!callFunc) return;
        this.isloading = true;
        this.scollAnimation();

        let delayMs = 200;
        // 'AfterLoginHandle' 登录成功之后方法，然后先断开链接，拼接token。
        if (callFunc == 'AfterLoginHandle' && this.socketState.socket)
        {
            await this.socketState.socket.stop();
            await this.initSocket();
            delayMs = 1000;
        }
        setTimeout(() => {
            this.showInitPage = false;
            if (callFuncData)
            {
                this.socketState.socket.send(callFunc, callFuncData);  
            }
            else
            {
                this.socketState.socket.send(callFunc);
            }
        }, delayMs);
    }

    /**
     * 组件创建钩子
     * @private
     * @returns void
     */
    private async created(): Promise<void>
    {
        if (await tools.isWechat() && !(await tools.isWeatchMini())) {
            let openid = localStorage.getItem("openid");
            if (!this.$route.query.openid && !openid) {
                this.wechatOauth();
            } else if (this.$route.query.openid) {
                localStorage.setItem("openid", this.$route.query.openid as string);
            }
        }
        // 获取机构信息
        this.getGeolocation();
    }

    /**
     * 微信授权
     * @private
     * @returns void
     */
     private async wechatOauth(): Promise<void> {
        let url = location.href;
        try {
        let { content: result } = await UserService.instance.wechatOauth(url, true);
        if (result && result.data) {
            location.href = result.data;
        }
        } catch (err) {
            console.log(err);
        }
    }

    /* 获取当前位置信息
    * @private
    * @returns void
    */
    private async getGeolocation(): Promise<void>
    {
        let appid = localStorage.getItem("appid");
        let employerConfig = globalConfig.employerConfig[appid];
        if (employerConfig && employerConfig.unLocation)
        {
            return;
        }
        this.$store.dispatch("setLocation", null);
        if (await tools.isWechat()) // 如果是微信环境
        {
            this.weixinLocation();
        }
        else
        {
            let data = await GaodeService.instance.getBaiduLocation();
            this.$store.dispatch("setLocation", data);
        }
    }

    /* 获取当前位置信息 -- 微信
    * @private
    * @returns void
    */
    private async weixinLocation(): Promise<void>
    {
        let currentUrl = location.href;
        let {content: result} = await WechatService.instance.getWechatJSSDK(currentUrl);
        let loca = await WechatService.instance.getWechatLocation(result); // 获取经纬度-腾讯
        let locat = await GaodeService.instance.getCity((loca as any).longitude, (loca as any).latitude); // 通过经纬度获取城市-高德
        this.$store.dispatch("setLocation", locat);
    }
 
    /**
     * 组件进来之前
     * @private
     * @returns void
     */
    private beforeRouteEnter(to, from, next): void
    {
        let orgInfo = localStorage.getItem("orgInfo");
        let appid = localStorage.getItem("appid");
        if (!tools.isAndroid() && `/${appid}${to.path}` !== location.pathname)
        {
            location.assign(`/${appid}${to.fullPath}`);
        }
        else
        {
            next();
        }
    }


}
