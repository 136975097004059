import { render, staticRenderFns } from "./update-phone.vue?vue&type=template&id=53e9b23f&scoped=true"
import script from "./update-phone.vue?vue&type=script&lang=ts"
export * from "./update-phone.vue?vue&type=script&lang=ts"
import style0 from "./update-phone.vue?vue&type=style&index=0&id=53e9b23f&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53e9b23f",
  null
  
)

export default component.exports