
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Toast, Dialog } from "vant";
import { PackageService, FamilyService } from "src/services";
import EnumUtils from "src/utils/enum-utils";
import { RELATION } from "src/enums";

@Component
export default class UDialog extends Vue
{

    /**
     * 标题
     * @private
     * @returns string
     */
    @Prop({type: String, default: ""})
    private title: string;


    /**
     * 内容
     * @private
     * @returns string
     */
     @Prop({type: String, default: ""})
    private content: string;


    /**
     * 是否显示背景
     * @private
     * @returns string
     */
     @Prop({type: Boolean, default: true})
    private isShowBg: boolean;


    /**
     * 返回弹框状态 
     * @private
     */
     protected confirmClose() {
        this.$emit("close")
     }

     // 手指可触摸滑动
     protected mounted(){
        let list = document.querySelector('.emoji-scrollbar')
        list.addEventListener('touchmove', e => e.stopPropagation(), false)
        
    }


    
}

