
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import eventBus from 'src/views/ai/utils/bus';

@Component
export default class PackageComparing extends Vue
{

    /**
     * 是否显示
     * @private
     * @returns boolean
     */
    private isShow: boolean = true;

    /**
     * 组件创建钩子
     * @private
     * @returns string
     */
     private created()
    {
        eventBus.$emit("changeShowFooter", false);
        this.checkeIds = this.dataSource.checkeIds || [];
    }

    /**
     * 数据
     * @private
     * @returns string
     */
     @Prop({type: Object, default: {title: "", data: [], button: { buttonText: "" }, checkeIds: []}})
    private dataSource: any;

    // 文字持续输入
    private onKeepTyping()
    {
        // 滚动到最底部
        this.$emit('scollAnimation');
    }

    /**
     * 文字输入完成
     * @private
     */
    private onCompleteTxt()
    {
        // 滚动到最底部
        this.$emit('scollAnimation');
    }

    /**
     * 选中id集合
     * @private
     */
    private checkeIds: Array<any> = [];

    /**
     * 改变当前选中套餐行
     * @private
     */
    private onChangeCur(id: any)
    {
        if (this.checkeIds.includes(id))
        {
            this.checkeIds = this.checkeIds.filter(item => item !== id);
        }
        else
        {
            this.checkeIds.push(id);
        }
    }

    /**
     * 关闭
     * @private
     */
    private onCancel () {
        eventBus.$emit("changeShowFooter", true);
        this.isShow = false;
    }
    
    /**
     * 执行方法
     * @private
     */
    private onToDo({callFunc, callFuncData})
    {
        callFuncData.checkeIds = this.checkeIds;
        this.onCancel();
        this.$emit("toDo", {callFunc, callFuncData});
    }
    
}
