
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Toast, Dialog } from "vant";
import { FamilyService } from "src/services";
import dayjs from "dayjs";
import patterns from "src/utils/patterns";
import IdentityCodeValid from 'src/utils/checkIdent'
import globalConfig from "src/config/index";
import EnumUtils from "src/utils/enum-utils";
import { RELATION, ID_TYPE, MARRIAGE_STATUS } from "src/enums";
import eventBus from 'src/views/ai/utils/bus';

@Component
export default class UpdateFamily extends Vue
{

    /**
     * 数据
     * @private
     * @returns string
     */
    @Prop({type: Object, default: {button: {buttonText: "", callFunc: "", callFuncData: null}, data: {}}})
    private dataSource: any;

    /**
     * 值发生变化
     * @private
     * @returns void
     */
    @Watch("dataSource",{immediate: true, deep: true})
    private onDataSourceChange(value: any): void
    {
        if (value && value.data)
        {
            this.modelData = value.data;
        }
    }

    /**
     * 是否显示
     * @private
     * @returns boolean
     */
    private isShow: boolean = true;

    /**
     * 性别列表
     * @private
     *
     */
     private sexList: any =
    [
        {
            id: 0,
            name: "女"
        },
        {
            id: 1,
            name: "男"
        }
    ]

    /**
     * 婚姻列表
     * @private
     *
     */
    private marriageList: any =
    [
        {
            id: 0,
            name: "已婚"
        },
        {
            id: 1,
            name: "未婚"
        }
    ]

    /**
     * 联系人数据模型
     * @private
     *
     */
    private modelData: any =
    {
        employerMemberId:null,
        memberType: 2,
        canUpdate: true,
        marriageStatusStr:"",
        memberName:"", // 用户姓名
        familyType: null, // 用户关系
        familyTypeStr: "",// 用户关系名称
        sexStr:"",
        phone: "", // 手机号
        sex: 0, // 用户性别
        birthday: null, // 生日
        cardTypeStr: null,
        cardType: null, // 证件类型
        marriageStatus: 1, // 用户婚姻状态
        cardNo: "", // 证件号
        remark: "" // 备注
    };

    /**
     * 日期控件
     * @private
     * @returns Date
     */
    private birthdayDate: Date = dayjs().toDate();

    /**
     * 日期控件
     * @private
     * @returns Date
     */
    private minDate: Date = new Date(1900, 1, 1);

    /**
     * 最大时间
     * @private
     * @returns Date
     */
    private maxDate: Date = dayjs().toDate();

    /**
     * 是否显示本人关系弹框
     * @private
     * @returns boolean
     */
    private showRelationPicker: boolean = false;

    /**
     * 是否显示婚姻关系弹框
     * @private
     * @returns boolean
     */
    private showMarriagePicker: boolean = false;

    /**
     * 是否显示性别弹框
     * @private
     * @returns boolean
     */
    private showSexPicker: boolean = false;

    /**
     * 出生日期弹框
     * @private
     * @returns boolean
     */
    private showDate: boolean = false;

    /**
     * 是否加载
     * @private
     * @returns boolean
     */
    private loading: boolean = false;

    /**
     * 显示身份证类型table
     * @private
     * @returns boolean
     */
    private showIdtypePicker: boolean = false;

    /**
     * 验证是否正确手机号
     * @private
     * @returns void
     */
    private validatorPhone(val: any): any
    {
        return patterns.mobile.test(val);
    }

    /**
     * 前一个页面名称
     * @private
     * @returns void
     */
    private routeName: string = "";


    /**
     * 组件进来之前
     * @private
     * @returns void
     */
     private beforeRouteEnter(to, from, next): void
    {
        next(vm => {
            // 通过“vm”访问组件实例`　　//可以通过vm.name去访问data里面的name属性，跟this.name一样效果
            vm.routeName = from.name
        })
    }


    /**
     * 与本人关系数据集合
     * @private
     * @returns Array<any>
     */
    private get relationshipList(): Array<any>
    {
        let relationshipList = EnumUtils.getEntries(RELATION);

        return relationshipList;
    }

    /**
     * 获取当前登入企业相关配置
     * @private
     * @returns void
     */
     protected get employerConfig(): any
    {
        let appid = localStorage.getItem("appid");

        let employerConfig = globalConfig.employerConfig[appid];

        return employerConfig;
    }

    /**
     * 是否是本人
     * @private
     * @returns boolean
     */
    private get isSelf(): boolean
    {
        return this.userInfo.userId == this.modelData.employerMemberId;
    }

    /**
     * 身份证类型数据集合
     * @private
     * @returns Array<any>
     */
    private get idTypeList(): Array<any>
    {
        return EnumUtils.getEntries(ID_TYPE);
    }

    /**
     * 结婚状态集合
     * @private
     * @returns Array<any>
     */
    private get marriageStatusList(): Array<any>
    {
        return EnumUtils.getEntries(MARRIAGE_STATUS);
    }

    /**
     * 用户id
     * @private
     * @returns string
     */
    private get userId(): string
    {
        return this.$route.query && (this.$route.query.userId as string) || "";
    }

    /**
     * 获取当前登入信息
     * @private
     * @returns void
     */
    private get userInfo(): any
    {
        return this.$store.getters.userInfo || {};
    }

    /**
     * 组件创建钩子
     * @private
     * @returns void
     */
    private created(): void
    {
        eventBus.$emit("changeShowFooter", false);
        if(this.userId)
        {
            this.getUserDetail(this.userId);
        }
    }

    /**
     * 验证身份证号
     * @private
     * @returns void
     */
    private validatorCardNo(value: any): boolean
    {
        if(this.modelData.cardType!==1)
        {
            return true;
        }
        let data = value.toUpperCase();
        return IdentityCodeValid(data);
    }

    /**
     * 确定日期选择
     * @private
     * @returns void
     */
    private onDateConfirm(date: any): void
    {
        if(date)
        {
            this.modelData.birthday = dayjs(date).format("YYYY/MM/DD");
        }
        
        this.showDate = false;
    }

    /**
     * 取消日期选择
     * @private
     * @returns void
     */
    private onDateCancel(): void
    {
        this.showDate = false;
    }

    /**
     * 获取身份类型
     * @private
     * @returns string
     */
    private getCardTypeStr(value: any): string
    {
        return EnumUtils.getEntry(value, ID_TYPE) && EnumUtils.getEntry(value, ID_TYPE).description;
    }

    /**
     * 获取关系
     * @private
     * @returns string
     */
    private getRelationName(value: any): string
    {
        return EnumUtils.getEntry(value, RELATION) && EnumUtils.getEntry(value, RELATION).description;
    }

    /**
     * 获取用户详情
     * @private
     * @returns Promise<void>
     */
     private async getUserDetail(userId: any): Promise<void>
    {
        try
        {
            let {content: result} =  await FamilyService.instance.getFamilyDetail(userId);

            if(result.data)
            {
                let perfectUserInfoStr = localStorage.getItem("perfectUserInfo");
                if(perfectUserInfoStr)
                {
                    let perfectUserInfo = JSON.parse(perfectUserInfoStr);
                    this.modelData = perfectUserInfo;
                    this.modelData.phone = result.data.phone; // 手机号
                }
                else
                {
                    this.modelData =
                    {
                        employerMemberId: result.data.employerMemberId,
                        memberType: result.data.memberType,
                        memberName: result.data.memberName, // 用户姓名
                        familyType: result.data.familyType, // 用户关系
                        familyTypeStr: this.getRelationName(result.data.familyType),// 用户关系名称
                        phone: result.data.phone, // 手机号
                        sex: result.data.sex, // 用户性别
                        sexStr: result.data.sexStr, // 用户性别
                        birthday: this.formatBirthday(result.data.birthday), // 生日
                        cardType: result.data.cardType, // 证件类型
                        cardTypeStr: this.getCardTypeStr(result.data.cardType),
                        marriageStatus: result.data.marriageStatus, // 用户婚姻状态
                        marriageStatusStr: result.data.marriageStatusStr, // 用户婚姻状态
                        cardNo: result.data.cardNo, // 证件号
                        canUpdate: result.data.canUpdate, // 是否可以编辑
                        remark: result.data.remark // 备注
                    };
                    if(result.data.sex == 2)
                    {
                        this.modelData.sex = null;
                        this.modelData.sexStr = "";
                    }
                    if(result.data.marriageStatus == 2)
                    {
                        this.modelData.marriageStatus = null;
                        this.modelData.marriageStatusStr = "";
                    }
                    this.birthdayDate = dayjs(result.data.birthday).isValid() ? dayjs(result.data.birthday).toDate(): dayjs().toDate();
                }
            }

        }
        catch(err)
        {
            Toast(err);
        }
    }

    /**
     * 格式化日期
     * @private
     * @returns void
     */
    private formatBirthday(date): string
    {
        if(dayjs(date).isValid())
        {
            return dayjs(date).format("YYYY/MM/DD");
        }
        else
        {
            return null;
        }
        
    }

    /**
     * 确定选择与本人关系
     * @private
     * @returns void
     */
    private onRelationConfirm(data: any): void
    {
        this.modelData.familyTypeStr = data.description;
        this.modelData.familyType = data.value;
        this.showRelationPicker = false;
    }

    /**
     * 确定性别选择
     * @private
     * @returns void
     */
    private onSexConfirm(data: any): void
    {
        this.modelData.sexStr = data.name;
        this.modelData.sex = data.id;
        this.showSexPicker = false;
    }

    /**
     * 确定选择身份证类型
     * @private
     * @returns void
     */
    private onIdTypeConfirm(data: any): void
    {
        this.modelData.cardTypeStr = data.description;
        this.modelData.cardType = data.value;
        this.showIdtypePicker = false;
    }

    /**
     * 确定选婚姻类型
     * @private
     * @returns void
     */
    private onMarriageConfirm(data: any): void
    {
        this.modelData.marriageStatusStr = data.name;
        this.modelData.marriageStatus = data.id;
        this.showMarriagePicker = false;
    }
    
    /**
     * 显示关系选项
     * @private
     * @returns void
     */
    private onShowRelation(): void
    {
        this.showRelationPicker = true;
    }

    /**
     * 修改手机号
     * @private
     * @returns void
     */
    private updatePhone(): void
    {
        localStorage.setItem("perfectUserInfo", JSON.stringify(this.modelData));
        this.onCancel();
        this.$router.push({name:"ai-update-phone", query: {isAi: true + "", isPerfect:"1", prevButton: this.dataSource.button.callFuncData ? JSON.stringify(this.dataSource.button.callFuncData) : "{}"}});
        
    }

    /**
     * 保存常用联系人
     * @private
     * @returns void
     */
    private async onSubmitContacts(): Promise<void>
    {
        // 验证表单数据
        if(!this.loading)
        {
            this.loading = true;
            localStorage.removeItem("perfectUserInfo");
            try
            {
                await FamilyService.instance.modifyFamily(this.modelData);
                
                let userInfoStr = localStorage.getItem("userInfo");
                if(userInfoStr)
                {
                    let userInfo = JSON.parse(userInfoStr);
                    userInfo.userName = this.modelData.memberName;
                    localStorage.setItem("userInfo", JSON.stringify(userInfo));
                }
                Toast.success("保存成功!");
                this.isShow = false;
                let {callFunc, callFuncData} = this.dataSource.button;
                this.$emit('toDo', {callFunc, callFuncData});
            }
            catch(err)
            {
                Toast(JSON.stringify(err));
            }
            finally
            {
                this.loading = false;
            }
        }
    }

    /**
     * 关闭
     * @private
     */
    private onCancel () {
        eventBus.$emit("changeShowFooter", true);
        this.isShow = false;
    }

    
    
}
