
import { Component, Vue, Watch } from "vue-property-decorator";
import { PackageService, WechatService, RightsService, AddPackageService } from "src/services";
import { Toast, Dialog } from "vant";
import { tools } from "src/utils";
import dayjs from "dayjs";
import goodsCategory from "src/components/goods-category"
import globalConfig from "src/config/index";
import udialog from "src/components/dialog";
import eventBus from 'src/views/ai/utils/bus';
import { log } from "console";

Component.registerHooks([
  "beforeRouteEnter",
  "beforeRouteLeave",
  "beforeRouteUpdate" // for vue-router 2.2+

]);

@Component({
    components:{
        goodsCategory,
        udialog
    }
})
export default class PackageDetail extends Vue
{

    private checked: boolean = false;
    /**
     * 是否显示弹框
     * @private
     * @returns boolean
     */
     private isShowDialog: boolean = false;

    /**
     * 弹框名称
     * @private
     * @returns boolean
     */
     private dialogName: string = "";


     /**
     * 弹框内容
     * @private
     * @returns boolean
     */
     private dialogContent: string = "";


    /**
     * 是否显示使用记录
     * @private
     * @returns string
     */
    private showHis: boolean = false;

    /**
     * 权益数据
     * @private
     * @returns any
     */
    private rightsData: any = {
        backgroundColor: "",
        id: null,
        isLimitSelfUse: true,
        name: "",
        products: [],
        rightsDesc: "",
        rightsNo: ""
    };

    /**
     * 显示详情
     * @private
     * @returns boolean
     */ 
    private showDetail: boolean = false;

    /**
     * 标签组名字
     * @private
     * @returns boolean
     */ 
    private tgaGroupName: string = "";

    /**
     * 固定套餐价格
     * @private
     * @returns number
     */ 
    private packageBasePrice: number = 0;

    /**
     * 加项包详情弹框
     * @private
     * @returns boolean
     */ 
    private showAddPackModal: boolean = false;

    /**
     * 加项包项目重复弹框
     * @private
     * @returns boolean
     */ 
    private showItemRepeatModal: boolean = false ;

    /**
     * 显示详情
     * @private
     * @returns boolean
     */ 
    private ball: any = {
        el: null,
        show: false,
    }

    /**
     * 当前加项包的项目
     * @private
     * @returns Array<any>
     */ 
    private addPackItems: Array<any> = [];

    /**
     * 重复项目
     * @private
     * @returns Array<any>
     */ 
    private repeatItem: Array<any> = [];

    /**
     * 所有数据集合
     * @private
     * @returns Array<string>
     */ 
    private dataList: Array<string> = [];

    /**
     * 展示分类集合
     * @private
     * @returns Array<string>
     */ 
    private titleList: Array<string> = [];

    /**
     * 标签数组
     * @private
     * @returns  Array<any>
     */ 
    private tagGroups: Array<any> = [];
        
    /**
     * 显示加项弹框
     * @private
     * @returns boolean
     */ 
    private showDialog: boolean = false;

    /**
     * 变量集合
     * @protected
     * @returns any
     */
    protected state: any = {
        listHeight: [],
        categoryHeight: [],
        currentIndex: 0
    };
    
    /**
     * 获取当前登入企业相关配置
     * @private
     * @returns number
     */
    protected get routerGo(): any
    {
        return this.$route && this.$route.query.routerGo ? this.$route.query.routerGo : 1;
    }

    /**
     * 获取当前登入企业相关配置
     * @private
     * @returns void
     */
    protected get employerConfig(): any
    {
        let appid = localStorage.getItem("appid");

        let employerConfig = globalConfig.employerConfig[appid];

        return employerConfig;
    }

    // 获取高度
    protected listHeight() {
        this.state.listHeight = [];
        this.state.categoryHeight = [];
        this.state.currentIndex = [];
        // 内容高度
        let items = document.getElementsByClassName('goods-category-item');
        let height = 0;

        for (let i = 0; i < items.length; i++) {
            this.state.listHeight.push(height);
            let offsetHeight = (items[i] as any).offsetHeight;
            height+=offsetHeight;
        }

        // 分类高度

        let categorys = document.getElementsByClassName('goods-category-type-li');

        let categoryHeight = 0;
        
        for (let i = 0; i < categorys.length; i++) {
            this.state.categoryHeight.push(categoryHeight);
            let offsetHeight = (categorys[i] as any).offsetHeight;
            categoryHeight+=offsetHeight;
        }
       

    }

    // 点击分类
    protected onCategoryClick(index)
    {
        this.state.currentIndex = index;
        (document.getElementById('goods-category-list') as any).scrollTop = this.state.listHeight[index]
    }

    // 滚动数据
    protected onscroll() {
        let scrolltop = document.getElementById('goods-category-list').scrollTop;
        
        for (let i = 0; i < this.state.listHeight.length; i++) {
            if ((scrolltop >  this.state.listHeight[i]) && (scrolltop <  this.state.listHeight[i + 1])) {
                this.state.currentIndex= i;
                (document.getElementById('goods-category-type') as any).scrollTop = this.state.categoryHeight[i-1 || 0]
            }
        }
    }

    /**
     * 搜索条件
     * @private
     * @returns any
     */
    private filter: any =
    {
        packageId: null, // 套餐id
        hospitalId: null, // 医院Id
        cityName: null, // 城市名称
        longitude: null, // 经度
        latitude: null // 纬度

    };

    /**
     * 资产抵扣信息
     * @private
     * @returns any
     */
    private assetConsumeInfo: any =
    {
        couponId: 0, // 钱包id
        isUsedAsset: false,     // 是否使用了资产
        needPayAmount: 0,       // 需要支付金额
        couponUsingAmount: 0,       // 报销
        walletUsingAmount: 0,    // 额度
        couponRate: 0    // 报销比例

    };

    /**
     * 选中的标签id集合
     * @private
     * @returns any
     */
    private selectTagIds: any = [];

    /**
     * 选中的健康产品和加项集合
     * @private
     * @returns any
     */
    private selectIds: any = [];

    /**
     * 请求数据源
     * @private
     * @returns any
     */
    private sourceData: any = {
        addOnPackages:[],
        optionalItems: [],
        tagGroupList: [],
        packageName:"",          // 套餐名称
        packageBasePrice:null,   // 套餐价格
        packageMarketPrice:null  // 划线价
    };

    /**
     * 选中的加项集合
     * @private
     * @returns any
     */
    private optionalItemIds: any = [];


    /**
     * 选中的名称集合
     * @private
     * @returns any
     */
     private nameList: any = [];

    /**
     * 选中的健康产品集合
     * @private
     * @returns any
     */
    private healthProductIds: any = [];

    /**
     * 选中加项包产品集合
     * @private
     * @returns any
     */
    private addOnPackageIds: any = [];

    /**
     * 选中的权益产品集合
     * @private
     * @returns any
     */
    private rightsIds: any = [];

    
    /**
     * 选中的加项列表集合
     * @private
     * @returns any
     */
    private addItemList:any = []


    /**
     * 医院/机构id
     * @private
     * @returns string
     */
    private get hospitalId(): string
    {
        return this.$route.query && (this.$route.query.hospitalId as string) || "";
    }

    /**
     * 处理全选和全不选
     * @private
     * @returns string
     */
    private checkboxChange(event) {
        if(this.checked) {
            // 如果购物车没有再加
            this.dataList.forEach((data)=> {
                (data as any).items.forEach((item)=> {
                    if(this.selectIds.indexOf(item.itemId) == -1) {
                        this.selectIds.push(item.itemId)
                    }

                    // 选中当前标签组下面的数据
                    if(this.addItemList.indexOf(item.itemId) == -1) {
                        this.addItemList.push(item.itemId)
                    }
                    
                })
            })
        } else {
            // 去除当前筛选的id  this.addItemList
            this.selectIds.forEach((id)=> {
                // 取消当前标签组下面的数据
                if(this.addItemList.indexOf(id) !== -1) {
                    this.selectIds = this.selectIds.filter((item)=>item !== id )
                }
            })
            this.addItemList = []

        }
    }

    /**
     * 选中自选金额比例
     * @private
     * @returns number
     */
    private get limitRatio(): number
    {
        if(this.assetConsumeInfo.couponMaxAmount > 0)
        {
            // 自选加权益 除以报销比例
            return this.optionalPrice/this.assetConsumeInfo.couponMaxAmount * 100;
        }
        
        return  0;
    }


    /**
     * 需要支付价格
     * @private
     * @returns number
     */
    private get payPrice(): string | number
    {
        return +this.packageBasePrice + this.optionalPrice;
    }

    /**
     * 自选金额
     * @private
     * @returns strig
     */
    private get optionalPrice(): number
    {
        // 过滤加项
        let selectItemPrice = [];
      
        this.sourceData.optionalItems.forEach((item: any) => {

            item.items.forEach(data =>
            {
                if(this.selectIds.indexOf(data.itemId) > -1)
                {
                    selectItemPrice.push(data.itemPrice);
                }

            });
        });
        
        
        // 计算总金额
        let sum = selectItemPrice.reduce((prve: number,curr: number) =>
        {
            return prve + curr;
        }, 0);

        return (sum).toFixed(2) || 0;
    }

    /**
     * 套餐id
     * @private
     * @returns string
     */
    private get packageId(): string
    {
        return this.$route.query && (this.$route.query.id as string) || "";
    }

    /**
     * 选中日期
     * @private
     * @returns string
     */
    private get selectDate(): string
    {
        return this.$route.query && (this.$route.query.date as string) || "";
    }

    /**
     * 选中日期时间段
     * @private
     * @returns string
     */
    private get physicalTimePeriod(): string
    {
        return this.$route.query && (this.$route.query.physicalTimePeriod as string) || "";
    }

    /**
     * 当前人员id
     * @private
     * @returns string
     */
    private get employerMemberId(): string
    {
        return this.$route.query && (this.$route.query.employerMemberId as string) || "";
    }

    /**
     * 当前位置
     * @private
     * @returns any
     */
    private get location(): any
    {
        return this.$store.getters.location || {};
    }

    /**
     * 获取用户信息
     * @private
     * @returns any
     */
    private get userInfo(): any
    {
        let userInfoStorge = localStorage.getItem("userInfo");

        if(userInfoStorge)
        {
            return JSON.parse(userInfoStorge)
        }

        return {};
    }


    /**
     * 显示加项详情
     * @private
     * @returns void
     */
    private onShowDetail(): void
    {
        // if(this.selectIds.length >0)
        // {
            this.showDetail = !this.showDetail;
            
        // }
        
    }

    /**
     * 加项包显示加项
     * @private
     * @returns void
     */
    private async showAddPackageModal(id): Promise<void>
    {
        let {content: res} =  await AddPackageService.instance.getAddPackageItem(id);
        if(res.data)
        {
            this.addPackItems = res.data;
            this.showAddPackModal = true;
        }
        
    }

    /**
     * 权益显示
     * @private
     * @returns void
     */
    private async showRightsModal(id): Promise<void>
    {
        let {content: res} =  await RightsService.instance.getEmployerRights(id);
        if(res.data)
        {
            this.rightsData = res.data;
            this.showHis = true;
        }
        
    }

    /**
     * 标签分类变更
     * @private
     * @returns void
     */
    private onTgaGroupChange(el) {
       this.selectTagIds = [];
       this.nameList = []
       // 清空筛选的标签名称
       this.addItemList = []
       this.handleViewData();
    }

    /**
     * 动画前
     * @private
     * @returns void
     */
    private beforeEnter(el) {
        
        // 购物车距离右边位置
        let elRight = this.getStyle(el, 'right') //购物车 right
        let elLeft = this.getStyle(el, 'left') //购物车 right
        // 购物车距离上边位置
        let elTop = this.getStyle(el, 'top') //购物车 top
        let elBottom = this.getStyle(el, 'bottom') //购物车 top

        let ele = this.ball.el //要添加购物车的商品
        let ract = ele.getBoundingClientRect() //商品的位置
        let x = ract.left - parseFloat(elLeft) // 计算小球移动的X轴的距离
        let y = window.innerHeight - ract.top - parseFloat(elBottom); // 计算小球移动的y轴的距离
        el.style.display = ''
        el.style.transform = `translateY(-${y}px) translateX(${x}px)` //重置小球的x轴位置

    }

    /**
     * 动画中
     * @private
     * @returns void
     */
    private enter(el, done) {
        let hh = el.offsetHeight //激发重绘
        el.style.transform = `translate(0, 0)`;
        el.addEventListener('transitionend', done)
    }
    
    /**
     * 动画后
     * @private
     * @returns void
     */
    private afterEnter(el) {
        this.ball.show = false
        el.style.display = 'none'
    }

    /**
     * 添加购物车
     * @private
     * @returns void
     */
    private addToCart(event) {
        this.ball.el = event.target
        this.ball.show = true
    }

    /**
     * 获取样式
     * @private
     * @returns void
     */
    private getStyle(el, attr) {
    return el.currentStyle
        ? el.currentStyle[attr]
        : getComputedStyle(el, null)[attr]
    }

    /**
     * 立即预约
     * @private
     * @returns void
     */
    private async onSubscribe(): Promise<void>
    {

        if(this.addOnPackageIds.length > 0)
        {
            let {content: res} = await AddPackageService.instance.checkAddOnPackageRepeat(this.addOnPackageIds);
            if(res.data && res.data.length>0)
            {
                this.repeatItem = res.data;
                this.showItemRepeatModal = true;
            }
            else
            {
                this.onNext();
            }
        }
        else
        {
            this.onNext();
        }
       
       
    }

    /**
     * 进入提交页面
     * @private
     * @returns void
     */
    private onNext(): void
    {
        let callFuncData = {
            employerMemberId: this.employerMemberId,
            packageId: this.packageId,
            hospitalId: this.hospitalId,
            physicalTime: this.selectDate && dayjs(this.selectDate).format("YYYY-MM-DD"),
            physicalTimePeriod: this.physicalTimePeriod,
            optionalItemIds: this.optionalItemIds,
            healthProductIds: this.healthProductIds,
            addOnPackageIds: this.addOnPackageIds,
            rightsIds: this.rightsIds,
            hasOptionalItems: true
        }
        eventBus.$emit("onDo", { callFunc: "ToBookOrder", callFuncData });
        this.$router.go(-this.routerGo);
    }

    /**
     * 项目变更
     * @private
     * @returns void
     */
    private itemChange(id, event,data): void
    {
        switch(data.itemTypeKind)
        {
            case 1:
                {
                    // 单个加项
                    let index = this.optionalItemIds.indexOf(id);
                    if(index == -1)
                    {
                        this.optionalItemIds.push(id);
                    }else
                    {
                        this.optionalItemIds.splice(index, 1);
                    }
                    break;
                }
            case 3:
                {
                    // 健康产品
                    let index = this.healthProductIds.indexOf(id);
                    if(index == -1)
                    {
                        this.healthProductIds.push(id);
                    }else
                    {
                        this.healthProductIds.splice(index, 1);
                    }
                    break;
                }
            case 5:
                {
                    // 加项包
                    let index = this.addOnPackageIds.indexOf(id);
                    if(index == -1)
                    {
                        this.addOnPackageIds.push(id);
                    }else
                    {
                        this.addOnPackageIds.splice(index, 1);
                    }
                    break;
                }
            case 99:
                {
                    // 权益
                    let index = this.rightsIds.indexOf(id);
                    if(index == -1)
                    {
                        this.rightsIds.push(id);
                    }else
                    {
                        this.rightsIds.splice(index, 1);
                    }
                    break;
                }
        }

        
        // 处理全选是否勾选
        let arr = []
        this.dataList.forEach((data)=> {
                (data as any).items.forEach((item)=> {
                    arr.push(item.itemId)
                })
        })
       
        // 添加购物车动画效果
        if(this.selectIds.indexOf(id)> -1)
        {

            if(event) {
                this.addToCart(event);
            } 
        } 
        
        if (!this.addItemList.includes(id) && arr.includes(id))
        {
            this.addItemList.push(id);
        }
        else
        {
            this.addItemList = this.addItemList.filter((item)=>item !== id)
        }

        // 如果筛选的结果和选中的相等 则全选 否则全不选
        if(arr.length == this.addItemList.length) {
            this.checked = true

        } else {
            this.checked = false
        }
    }

    /**
     * 自选项目变更时
     * @private
     * @returns void
     */
    private async onOptopnalItemChange(): Promise<void>
    {
        
        try
        {
            let data =
            {
                employerMemberId: this.employerMemberId,
                basePrice: this.packageBasePrice,
                optionalPrice: this.optionalPrice,
            }  

            let {content: result} =  await PackageService.instance.getMedicalPackagePrice(data, false);

            this.assetConsumeInfo= result.data;
            
        }
        catch(err)
        {
            Toast(err);
        }
    }

    /**
     * 组件创建
     * @private
     * @returns void
     */
    private created(): void
    {   
        if(this.hospitalId)
        {
            this.filter.hospitalId = this.hospitalId;
        }


        this.filter.packageId = this.packageId;
        this.filter.employerMemberId = this.employerMemberId;

        this.getOptionalPackageInfo(this.filter);

    }

    /**
     * 数据回显
     * @private
     * @returns void
     */
    private dataEcho(): void
    {   
        // 数据回显
        if (this.$route.query)
        {
            if (this.$route.query.optionalItemIds)
            {
                this.optionalItemIds = ((this.$route.query.optionalItemIds as string).split(',') as Array<string>).map(res => +res);
                this.selectIds = [...this.selectIds, ...this.optionalItemIds];
            }
            if (this.$route.query.rightsIds)
            {
                this.rightsIds = ((this.$route.query.rightsIds as string).split(',') as Array<string>).map(res => +res);
                this.selectIds = [...this.selectIds, ...this.rightsIds];
            }
            if (this.$route.query.addOnPackageIds)
            {
                this.addOnPackageIds = ((this.$route.query.addOnPackageIds as string).split(',') as Array<string>).map(res => +res);
                this.selectIds = [...this.selectIds, ...this.addOnPackageIds];
            }
            if (this.$route.query.healthProductIds)
            {
                this.healthProductIds = ((this.$route.query.healthProductIds as string).split(',') as Array<string>).map(res => +res);
                this.selectIds = [...this.selectIds, ...this.healthProductIds];
                    
            }
        }
    }

    /**
     * 获取套餐详情
     * @private
     * @returns void
     */
    private async getOptionalPackageInfo(data: any): Promise<void>
    {
        try
        {
            let questionnaireRId = this.employerMemberId;
            let {content: result} =  await PackageService.instance.getOptionalPackageInfo(Object.assign(data,{questionnaireRId}));
            if(result.data)
            {
                // 固定项金额
                this.packageBasePrice = result.data.packageBasePrice;
                
                // 请求后端取出需要支付金额
                this.onOptopnalItemChange();
                // 请求源数据
                this.sourceData = result.data;
                // 标签数据
                this.tagGroups = result.data.tagGroupList || [];

                // 默认选中第一个分组
                if(this.tagGroups.length && this.tagGroups[0].tagGroupId == 99999) {
                    this.onCheckTag(0,this.tagGroups[0].tags[0])
                } else {
                    this.handleViewData();
                }
                this.dataEcho();
            }
            
        }
        catch(err)
        {
            Toast(err);
        }
    }

    /**
     * 处理展示数据
     * @private
     * @returns void
     */
    private handleViewData(selectTagIds=[])
    {

        // 重制数据
        this.titleList =[];
        this.dataList = [];
        let allItems = [...this.sourceData.optionalItems];
        
       
        if(selectTagIds.length == 0)
        {
            // 未选中标签处理
            allItems.forEach(item=>
            {
                this.titleList.push(item.itemTypeName);
            })

            this.dataList = allItems;
        }
        else
        {
            
            // 有标签时处理
            // 过滤数据
            let filterItems = [];
           
            allItems.forEach(data=>
            {
                let items = [];
                data.items.forEach(item=>
                {
                    // 过滤是加项的数据
                    // itemTypeKind  1:单选加项 3:健康产品 5:加项包 99:权益
                    if((item.itemTypeKind == 1 || item.itemTypeKind == 3) && item.tagIds.length>0)
                    {
                        for (var i = 0; i < item.tagIds.length; i++) {
                            if(selectTagIds.indexOf(item.tagIds[i])!=-1)
                            {
                                items.push(Object.assign({},item));
                                break;
                            }
                        }
                    }
                })

                // 有数据就添加
                if(items.length!=0)
                {
                    filterItems.push(Object.assign({},{
                        itemTypeId: data.itemTypeId,
                        itemTypeName: data.itemTypeName,
                        items: items
                    }))

                    
                }
            })

            filterItems.forEach(item=>
            {
                this.titleList.push(item.itemTypeName);
            })


            this.$nextTick(()=>
            {
                this.dataList = filterItems;
                
                let arr = []

                // 筛选选中的对象
                this.dataList.forEach((data)=> {
                    (data as any).items.forEach((item)=> {
                        if(this.selectIds.indexOf(item.itemId) !== -1) {
                            this.addItemList.push(item.itemId)
                        }

                        arr.push(item.itemId)
                    })
                })
                // 处理全选和全部选
                if(arr.length == this.addItemList.length) {
                    this.checked = true
                } else {
                    this.checked = false
                }

                
            })
        }
       
        this.$nextTick(()=>
        {
            this.listHeight();
        })
       
    }

    /**
     * 显示提示
     * @private
     * @returns void
     */
    private showTip(remark: string, name: string): void
    {
//         Dialog.alert({
//             title: name,
//             confirmButtonColor: "#2FA5FF",
//             messageAlign: "left",
//             message: remark || `

// 为保障员工健康，企业为员工提供了相应额度的报销福利，在额度内选购权益或加项的费用由企业承担${this.assetConsumeInfo.couponRate}%，剩余${100-this.assetConsumeInfo.couponRate}%由员工自费。(超出额度的部分由员工全自费)以额度为1000元为例，若员工选购了1000元的权益和加项，则企业支付${this.assetConsumeInfo.couponRate*1000/100}元，员工自费${1000-this.assetConsumeInfo.couponRate*1000/100}元。`,
//             confirmButtonText: "确定"
//         });
        this.dialogName = name
        this.dialogContent = remark || `
        为保障员工健康，企业为员工提供了相应额度的报销福利，在额度内选购权益或加项的费用由企业承担${this.assetConsumeInfo.couponRate}%，剩余${100-this.assetConsumeInfo.couponRate}%由员工自费。(超出额度的部分由员工全自费)以额度为1000元为例，若员工选购了1000元的权益和加项，则企业支付${this.assetConsumeInfo.couponRate*1000/100}元，员工自费${1000-this.assetConsumeInfo.couponRate*1000/100}元。`
        this.isShowDialog = true
    }

    /**
     * 判断两个数组是否有相同的数字
     * @private
     * @returns void
     */
    private isRepeat(arr1: Array<any>, arr2: Array<any>): boolean
    {
        for(let i=0;i<arr1.length;i++)
        {
            if(arr2.indexOf(arr1[i])>-1)
            {
                return true;
            }
        }
        return false;
       
    }
    
    /**
     * 更改标签选中状态
     * @private
     * @returns void
     */
    private onCheckTag(index: any, item: any): void
    {
        // 存储选中的标签名称
        let i = this.nameList.indexOf(item.tagName)
        if(i == -1) {
            this.nameList.push(item.tagName)
        } else {
            this.nameList.splice(i,1)
        }

        // 标签切换 选中重置为空
        this.checked = false
        this.addItemList = []
        
        let fileIndex = this.selectTagIds.indexOf(item.tagId);
        // 处理标签高亮
        if(fileIndex == -1)
        {
            this.selectTagIds.push(item.tagId);
        }
        else
        {
            this.selectTagIds.splice(fileIndex, 1);
        }
        this.handleViewData(this.selectTagIds)

    }
    
    /**
     * 获取企业参数配置
     * @private
     * @returns any
     */
    private get generalSettings(): any
    {
        let generalSettingsValue = localStorage.getItem("generalSettings");
        if(generalSettingsValue)
        {
            return JSON.parse(generalSettingsValue);
        }
        return [];
    }

    /**
     * 是否显示配置
     * @protected
     * @returns boolean
     */
    protected showStting(code: string): boolean
    {
        // A1001 -- 体检套餐列表页、详情页、提交订单页及订单详情页均不显示体检套餐价格及企业报销金额。
        // A1002 -- 体检套餐列表页、详情页均不显示体检套餐划线价格。
        // A2002 -- 开启后，体检套餐的自选加项页面底部将显示额度使用情况，但是额度为0时开启此开关后也不会显示额度使用进度。
        let data = this.generalSettings.filter(res => res.code == code);
        if (data.length > 0)
        {
            return data[0].isOpen;
        }
        else
        {
            return true;
        }
    }

}
