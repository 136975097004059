
import { Component, Vue, Prop } from "vue-property-decorator";
import eventBus from 'src/views/ai/utils/bus';

@Component
export default class FamilyList extends Vue
{

    /**
     * 数据
     * @private
     * @returns string
     */
    @Prop({type: Object, default: {button: {buttonText: "", callFunc: "", callFuncData: null}, data: []}})
    private dataSource: any;

    /**
     * 是否显示
     * @private
     * @returns boolean
     */
    private isShow: boolean = true;

    /**
     * 关闭
     * @private
     */
    private onCancel () {
        eventBus.$emit("changeShowFooter", true);
        this.isShow = false;
    }

    /**
     * 组件创建钩子
     * @private
     * @returns void
     */
     private created(): void
    {
        eventBus.$emit("changeShowFooter", false);
    }

    /**
     * 执行方法
     * @private
     */
    private onToDo({callFunc, callFuncData})
    {
        this.$emit("toDo", {callFunc, callFuncData});
        this.onCancel();
    }
    
}
