
import { Component, Vue, Prop } from "vue-property-decorator";
import { OrderService } from "src/services";
import { Toast } from "vant";
import { Payment_Type } from "src/enums";
import { tools } from "src/utils";
import globalConfig from "src/config/index";
import eventBus from 'src/views/ai/utils/bus';
declare let WeixinJSBridge: any 

@Component
export default class ToPay extends Vue
{

    /**
     * 数据
     * @private
     * @returns string
     */
    @Prop({type: Object, default: {title: "", data: {}}})
    private dataSource: any;

    /**
     * 是否显示
     * @private
     * @returns boolean
     */
    private isShow: boolean = true;

    /**
     * 关闭
     * @private
     */
    private onCancel () {
        eventBus.$emit("changeShowFooter", true);
        this.isShow = false;
    }

    private payType: string = "1";

    /**
     * 判断是否数微信环境
     * @private
     * @returns any
     */
    private isWechat: any = false;

    /**
     * 订单编号
     * @private
     * @returns string
     */
    private orderInfo: any =
    {
        createdTime: "",
        id: null,
        needPayedAmount: 0,
        orderKind: null,
        orderKindStr: "",
        orderNo: "",
        payDeadlineTime: "",
        productType: null,
        productTypeStr: "",
        status: 1,
        statusStr:"",
        targetId: null,
        targetMainPictUrl: "",
        targetName: "",
        targetPrice: 0,
        targetQty: 1
    }

    /**
     * 获取当前登入企业相关配置
     * @private
     * @returns void
     */
     protected get employerConfig(): any
    {
        let appid = localStorage.getItem("appid");

        let employerConfig = globalConfig.employerConfig[appid];

        return employerConfig;
    }

    /**
     * 是否匿名
     * @private
     * @returns string
     */
    private get isAnonymous(): boolean
    {
        return (this.$route.query && this.$route.query.anonymous == "1") ? true : false;
    }

    /**
     * 获取数据
     * @private
     * @returns void
     */
     private get appid(): string
    {
        return localStorage.getItem("appid") || "";
    }

    /**
     * 组件创建钩子
     * @private
     * @returns string
     */
    private async created()
    {
        this.isWechat = await tools.isWechat();
        eventBus.$emit("changeShowFooter", false);
        this.getPayPreByNo();
    }

    /**
    * 获取支付信息
    */
    private async getPayPreByNo(): Promise<void>
    {
        try
        {
            if (this.isAnonymous)
            { 
                let {content: result} =  await OrderService.instance.getPayPreByNoAnonymous(this.dataSource.orderNo);
                
                if(result.data)
                {
                    this.orderInfo = result.data;
                }

            }
            else
            {

                let {content: result} =  await OrderService.instance.getPayPreByNo(this.dataSource.orderNo);
                
                if(result.data)
                {
                    this.orderInfo = result.data;
                }
            }

        }
        catch(err)
        {
            Toast(err);
        }
    }

    /**
    * 支付失败跳转
    */
    private payFail(): void
    {
        this.onCancel();
        // if(this.orderInfo.orderKind == 4)
        // {
        //     // 实物订单
        //     this.$router.push({name: "goods-order-detail", query:{id: this.orderInfo.id}});
            
        // }
        // else if(this.orderInfo.orderKind == 6)
        // {
        //     // 预约服务
        //     this.$router.push({name: "medical-order-detail", query:{id: this.orderInfo.id}});
        // }
        // else if(this.orderInfo.orderKind == 2)
        // {
        //     if (this.isAnonymous)
        //     {  
        //         // 体检订单
        //         this.$router.push({name: "orderPay", query:{orderNo: this.dataSource.orderNo }});
        //     }
        //     else
        //     {
        //         // 体检订单
        //         this.$router.push({name: "orderDetail", params:{id: this.orderInfo.id}});
        //     }
        // }
    }


    /**
    * 获取支付信息
    */
    private async toPay(): Promise<void>
    {
        let quitUrl = `${location.origin}/${this.appid}/ai`; // 取消
        let returnUrl = `${location.origin}/${this.appid}/ai`; // 成功

        let type: Payment_Type = Payment_Type.WechatJSAPI;// 实际支付方式
        if(this.payType == "1")
        {
            // 微信支付
            if(!this.isWechat)
            {
                type=Payment_Type.WechatMWEB;
            }
        }
        else
        {
            // 支付宝支付
            type = Payment_Type.AliPayWap;
            // 体检订单
            returnUrl = `${location.origin}/${this.appid}/ai`;
            quitUrl = `${location.origin}/${this.appid}/ai`;
        }

        try
        {

            let origin = location.origin;
            if (this.employerConfig && this.employerConfig.wptSetReferer)   // 应用于vivo套壳
            {
                if (wpt)
                {
                    wpt.setReferer(origin);
                }
            }
            if (this.isAnonymous)
            { 
                 let {content: result} =  await OrderService.instance.toPayAnonymous({
                    orderNo: this.dataSource.orderNo,
                    quitUrl: quitUrl,
                    returnUrl: returnUrl
                },type);

                if(result.data)
                {
                    this.onBridgeReady(result.data);
                }

            }
            else
            {

                let openid = localStorage.getItem("openid");
                let {content: result} =  await OrderService.instance.toPay({
                    orderNo: this.orderInfo.orderNo,
                    quitUrl: quitUrl,
                    returnUrl: returnUrl
                },type, openid);

                if(result.data)
                {
                    this.onBridgeReady(result.data);
                }
            }
        }
        catch(err)
        {
            Toast(err);
        }
    }

    /**
     * 支付
     * @static
     * @param  {any} model
     * @returns void
     */
    public onBridgeReady(model: any): void
    {
        let self = this;
        switch (model.paymentType as Payment_Type)
        {
            case Payment_Type.WechatJSAPI:  // 微信公众号
                {
                    let data = model.payRes && JSON.parse(model.payRes);

                    WeixinJSBridge.invoke(
                        "getBrandWCPayRequest",
                        {
                            appId: data.appId, // 公众号名称，由商户传入
                            timeStamp: data.timeStamp, // 时间戳，自1970年以来的秒数
                            nonceStr: data.nonceStr, // 随机串
                            package: data.package, //
                            signType: data.signType, // 微信签名方式:
                            paySign: data.paySign // 微信签名
                        },
                        function(res)
                        {
                            if (res.err_msg === "get_brand_wcpay_request:ok")
                            {
                                self.onCancel();
                                // self.$router.go(-1);
                            }
                            else if(res.err_msg === "get_brand_wcpay_request:cancel")
                            {
                                // 取消支付
                                self.payFail();
                            }
                            else
                            {
                                // 取消支付
                                self.payFail();
                            }
                        }
                    );
                }
                break;
            case Payment_Type.WechatMWEB:  // 微信H5
                {
                    let href = window.location.href;
                    setTimeout(() => {
                        let url = `${model.payRes}&redirect_url=${encodeURIComponent(href)}`;
                        window.location.assign(url);
                    }, 1);
                }
                break;
            case Payment_Type.WechatNATIVE:  // 微信:扫码NATIVE
            case Payment_Type.WechatAPP:  // 微信:APP支付
            case Payment_Type.AliPayWap:  // 支付宝:WEB支付
            {
                let u = navigator.userAgent;
                let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端

                let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端

                if(isiOS)
                {
                    const div=document.createElement('divform');
                    div.innerHTML= model.payRes; // data就是接口返回的form 表单字符串

                    document.body.appendChild(div);
                    document.forms[0].submit();
                }
                else
                {
                    document.write(model.payRes);
                }
               
                break;
            }
            default:
                Toast("暂不支持该支付方式！");
            break;
        }
        
    }

    
    
}
